
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {
    "buildId": "4dd308a6-3f51-4e35-a1d4-832949764e98"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, inlineConfig)
