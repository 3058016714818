import { default as _91claimId_93Kl2KNKZupTMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/claim-lab/pages/[connectorId]/claim-lab/[claimId].vue?macro=true";
import { default as indexo0yzZslVtaMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/[connectorId]/claim-lab/index.vue?macro=true";
import { default as _91connectorId_93i1YP7rvS1fMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/claim-lab/pages/[connectorId].vue?macro=true";
import { default as api_45clientsVFUr8zL9U6Meta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/account/api-clients.vue?macro=true";
import { default as index0LAIYQtICYMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/account/index.vue?macro=true";
import { default as usersAGi3jjcc8pMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/account/users.vue?macro=true";
import { default as api_45adminqcHVvE34epMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/api-admin.vue?macro=true";
import { default as app_45configROonmlp19XMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/app-config.vue?macro=true";
import { default as create_45workspace1sW3IMP6WfMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/index/create-workspace.vue?macro=true";
import { default as _91id_93WLPwNz5K4KMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/index/edit-workspace/[id].vue?macro=true";
import { default as indexuZ66wdapSfMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/index.vue?macro=true";
import { default as _91id_93ttEV57Jo3AMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/users/[id].vue?macro=true";
import { default as createQrJrdcYHNeMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/users/create.vue?macro=true";
import { default as indexLN786kUmmtMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/users/index.vue?macro=true";
import { default as usersxuGJjs4nGzMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/users.vue?macro=true";
import { default as _91connId_93F8QQqhcDJrMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/workspace/[id]/index/connector/[connId].vue?macro=true";
import { default as indexf6slBgNFMCMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/workspace/[id]/index/connector/index.vue?macro=true";
import { default as editOepfgOIEtdMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/workspace/[id]/index/edit.vue?macro=true";
import { default as indexxHxerlsflvMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/workspace/[id]/index.vue?macro=true";
import { default as _91id_93v1469Za2x5Meta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/workspace/[id].vue?macro=true";
import { default as indexbpOO2lCOzaMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/workspace/index.vue?macro=true";
import { default as app_45statusZEnAU896xcMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/app-status.vue?macro=true";
import { default as changelogYGFNpog9WhMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/changelog.vue?macro=true";
import { default as influencing_45claims5V4wM3daSqMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/claims/[id]/[insightId]/influencing-claims.vue?macro=true";
import { default as _91id_939XucZPcfQKMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/claims/[id].vue?macro=true";
import { default as claimsOysYGSNpoIMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/claims.vue?macro=true";
import { default as dashboard7aDEZpjjxgMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/dashboard.vue?macro=true";
import { default as edithE4xXytR0nMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/exclusions/[id]/edit.vue?macro=true";
import { default as indexPBwcwJSQ6OMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/exclusions/[id]/index.vue?macro=true";
import { default as createRyEyPG0cYZMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/exclusions/create.vue?macro=true";
import { default as indexsgjVnnDfxKMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/exclusions/index.vue?macro=true";
import { default as exclusions16cBmGOhmaMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/exclusions.vue?macro=true";
import { default as indexbW6u8wYtA5Meta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/index.vue?macro=true";
import { default as maintenance1sgkSZCJjXMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/maintenance.vue?macro=true";
import { default as providers_45impacted38lZbCxVGbMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/policies/[id]/providers-impacted.vue?macro=true";
import { default as _91id_93an0fGmoNVLMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/policies/[id].vue?macro=true";
import { default as indexwvymHmXbtUMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/policies/index.vue?macro=true";
import { default as policies9OzHSVcefEMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/policies.vue?macro=true";
import { default as reset_45password_45confirmDVYBdp7NwMMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/reset-password-confirm.vue?macro=true";
import { default as reset_45passwordUYH1NBMxqeMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/reset-password.vue?macro=true";
import { default as signinuAfwLtGmyOMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/signin.vue?macro=true";
import { default as confirmZzjSyJIsY1Meta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/signup/confirm.vue?macro=true";
import { default as indexaIQvO1UP6SMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/signup/index.vue?macro=true";
import { default as signupc5AhWqp4MDMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/signup.vue?macro=true";
import { default as welcomeH8NtQEcgulMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/welcome.vue?macro=true";
import { default as auth_45callback852wOlvwczMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-callback.vue?macro=true";
import { default as auth_45loginj4HtcCjkEiMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-login.vue?macro=true";
import { default as auth_45statusPp8tL2HSytMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-status.vue?macro=true";
import { default as samlgsDNFHMzJUMeta } from "/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth/src/pages/saml.vue?macro=true";
export default [
  {
    name: _91connectorId_93i1YP7rvS1fMeta?.name ?? "connectorId",
    path: _91connectorId_93i1YP7rvS1fMeta?.path ?? "/:connectorId()",
    meta: _91connectorId_93i1YP7rvS1fMeta || {},
    alias: _91connectorId_93i1YP7rvS1fMeta?.alias || [],
    redirect: _91connectorId_93i1YP7rvS1fMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/claim-lab/pages/[connectorId].vue").then(m => m.default || m),
    children: [
  {
    name: _91claimId_93Kl2KNKZupTMeta?.name ?? "connectorId-claim-lab-claimId",
    path: _91claimId_93Kl2KNKZupTMeta?.path ?? "claim-lab/:claimId()",
    meta: _91claimId_93Kl2KNKZupTMeta || {},
    alias: _91claimId_93Kl2KNKZupTMeta?.alias || [],
    redirect: _91claimId_93Kl2KNKZupTMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/claim-lab/pages/[connectorId]/claim-lab/[claimId].vue").then(m => m.default || m)
  },
  {
    name: indexo0yzZslVtaMeta?.name ?? "connectorId-claim-lab",
    path: indexo0yzZslVtaMeta?.path ?? "claim-lab",
    meta: indexo0yzZslVtaMeta || {},
    alias: indexo0yzZslVtaMeta?.alias || [],
    redirect: indexo0yzZslVtaMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/[connectorId]/claim-lab/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: api_45clientsVFUr8zL9U6Meta?.name ?? "account-api-clients",
    path: api_45clientsVFUr8zL9U6Meta?.path ?? "/account/api-clients",
    meta: api_45clientsVFUr8zL9U6Meta || {},
    alias: api_45clientsVFUr8zL9U6Meta?.alias || [],
    redirect: api_45clientsVFUr8zL9U6Meta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/account/api-clients.vue").then(m => m.default || m)
  },
  {
    name: index0LAIYQtICYMeta?.name ?? "account",
    path: index0LAIYQtICYMeta?.path ?? "/account",
    meta: index0LAIYQtICYMeta || {},
    alias: index0LAIYQtICYMeta?.alias || [],
    redirect: index0LAIYQtICYMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: usersAGi3jjcc8pMeta?.name ?? "account-users",
    path: usersAGi3jjcc8pMeta?.path ?? "/account/users",
    meta: usersAGi3jjcc8pMeta || {},
    alias: usersAGi3jjcc8pMeta?.alias || [],
    redirect: usersAGi3jjcc8pMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/account/users.vue").then(m => m.default || m)
  },
  {
    name: api_45adminqcHVvE34epMeta?.name ?? "admin-api-admin",
    path: api_45adminqcHVvE34epMeta?.path ?? "/admin/api-admin",
    meta: api_45adminqcHVvE34epMeta || {},
    alias: api_45adminqcHVvE34epMeta?.alias || [],
    redirect: api_45adminqcHVvE34epMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/api-admin.vue").then(m => m.default || m)
  },
  {
    name: app_45configROonmlp19XMeta?.name ?? "admin-app-config",
    path: app_45configROonmlp19XMeta?.path ?? "/admin/app-config",
    meta: app_45configROonmlp19XMeta || {},
    alias: app_45configROonmlp19XMeta?.alias || [],
    redirect: app_45configROonmlp19XMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/app-config.vue").then(m => m.default || m)
  },
  {
    name: indexuZ66wdapSfMeta?.name ?? "admin",
    path: indexuZ66wdapSfMeta?.path ?? "/admin",
    meta: indexuZ66wdapSfMeta || {},
    alias: indexuZ66wdapSfMeta?.alias || [],
    redirect: indexuZ66wdapSfMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/index.vue").then(m => m.default || m),
    children: [
  {
    name: create_45workspace1sW3IMP6WfMeta?.name ?? "admin-index-create-workspace",
    path: create_45workspace1sW3IMP6WfMeta?.path ?? "create-workspace",
    meta: create_45workspace1sW3IMP6WfMeta || {},
    alias: create_45workspace1sW3IMP6WfMeta?.alias || [],
    redirect: create_45workspace1sW3IMP6WfMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/index/create-workspace.vue").then(m => m.default || m)
  },
  {
    name: _91id_93WLPwNz5K4KMeta?.name ?? "admin-index-edit-workspace-id",
    path: _91id_93WLPwNz5K4KMeta?.path ?? "edit-workspace/:id()",
    meta: _91id_93WLPwNz5K4KMeta || {},
    alias: _91id_93WLPwNz5K4KMeta?.alias || [],
    redirect: _91id_93WLPwNz5K4KMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/index/edit-workspace/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: usersxuGJjs4nGzMeta?.name ?? undefined,
    path: usersxuGJjs4nGzMeta?.path ?? "/admin/users",
    meta: usersxuGJjs4nGzMeta || {},
    alias: usersxuGJjs4nGzMeta?.alias || [],
    redirect: usersxuGJjs4nGzMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/users.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93ttEV57Jo3AMeta?.name ?? "admin-users-id",
    path: _91id_93ttEV57Jo3AMeta?.path ?? ":id()",
    meta: _91id_93ttEV57Jo3AMeta || {},
    alias: _91id_93ttEV57Jo3AMeta?.alias || [],
    redirect: _91id_93ttEV57Jo3AMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/users/[id].vue").then(m => m.default || m)
  },
  {
    name: createQrJrdcYHNeMeta?.name ?? "admin-users-create",
    path: createQrJrdcYHNeMeta?.path ?? "create",
    meta: createQrJrdcYHNeMeta || {},
    alias: createQrJrdcYHNeMeta?.alias || [],
    redirect: createQrJrdcYHNeMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/users/create.vue").then(m => m.default || m)
  },
  {
    name: indexLN786kUmmtMeta?.name ?? "admin-users",
    path: indexLN786kUmmtMeta?.path ?? "",
    meta: indexLN786kUmmtMeta || {},
    alias: indexLN786kUmmtMeta?.alias || [],
    redirect: indexLN786kUmmtMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/users/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91id_93v1469Za2x5Meta?.name ?? undefined,
    path: _91id_93v1469Za2x5Meta?.path ?? "/admin/workspace/:id()",
    meta: _91id_93v1469Za2x5Meta || {},
    alias: _91id_93v1469Za2x5Meta?.alias || [],
    redirect: _91id_93v1469Za2x5Meta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/workspace/[id].vue").then(m => m.default || m),
    children: [
  {
    name: indexxHxerlsflvMeta?.name ?? "admin-workspace-id",
    path: indexxHxerlsflvMeta?.path ?? "",
    meta: indexxHxerlsflvMeta || {},
    alias: indexxHxerlsflvMeta?.alias || [],
    redirect: indexxHxerlsflvMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/workspace/[id]/index.vue").then(m => m.default || m),
    children: [
  {
    name: _91connId_93F8QQqhcDJrMeta?.name ?? "admin-workspace-id-index-connector-connId",
    path: _91connId_93F8QQqhcDJrMeta?.path ?? "connector/:connId()",
    meta: _91connId_93F8QQqhcDJrMeta || {},
    alias: _91connId_93F8QQqhcDJrMeta?.alias || [],
    redirect: _91connId_93F8QQqhcDJrMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/workspace/[id]/index/connector/[connId].vue").then(m => m.default || m)
  },
  {
    name: indexf6slBgNFMCMeta?.name ?? "admin-workspace-id-index-connector",
    path: indexf6slBgNFMCMeta?.path ?? "connector",
    meta: indexf6slBgNFMCMeta || {},
    alias: indexf6slBgNFMCMeta?.alias || [],
    redirect: indexf6slBgNFMCMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/workspace/[id]/index/connector/index.vue").then(m => m.default || m)
  },
  {
    name: editOepfgOIEtdMeta?.name ?? "admin-workspace-id-index-edit",
    path: editOepfgOIEtdMeta?.path ?? "edit",
    meta: editOepfgOIEtdMeta || {},
    alias: editOepfgOIEtdMeta?.alias || [],
    redirect: editOepfgOIEtdMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/workspace/[id]/index/edit.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: indexbpOO2lCOzaMeta?.name ?? "admin-workspace",
    path: indexbpOO2lCOzaMeta?.path ?? "/admin/workspace",
    meta: indexbpOO2lCOzaMeta || {},
    alias: indexbpOO2lCOzaMeta?.alias || [],
    redirect: indexbpOO2lCOzaMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/admin/workspace/index.vue").then(m => m.default || m)
  },
  {
    name: app_45statusZEnAU896xcMeta?.name ?? "app-status",
    path: app_45statusZEnAU896xcMeta?.path ?? "/app-status",
    meta: app_45statusZEnAU896xcMeta || {},
    alias: app_45statusZEnAU896xcMeta?.alias || [],
    redirect: app_45statusZEnAU896xcMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/app-status.vue").then(m => m.default || m)
  },
  {
    name: changelogYGFNpog9WhMeta?.name ?? "changelog",
    path: changelogYGFNpog9WhMeta?.path ?? "/changelog",
    meta: changelogYGFNpog9WhMeta || {},
    alias: changelogYGFNpog9WhMeta?.alias || [],
    redirect: changelogYGFNpog9WhMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/changelog.vue").then(m => m.default || m)
  },
  {
    name: claimsOysYGSNpoIMeta?.name ?? "claims",
    path: claimsOysYGSNpoIMeta?.path ?? "/claims",
    meta: claimsOysYGSNpoIMeta || {},
    alias: claimsOysYGSNpoIMeta?.alias || [],
    redirect: claimsOysYGSNpoIMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/claims.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_939XucZPcfQKMeta?.name ?? "claims-id",
    path: _91id_939XucZPcfQKMeta?.path ?? ":id()",
    meta: _91id_939XucZPcfQKMeta || {},
    alias: _91id_939XucZPcfQKMeta?.alias || [],
    redirect: _91id_939XucZPcfQKMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/claims/[id].vue").then(m => m.default || m),
    children: [
  {
    name: influencing_45claims5V4wM3daSqMeta?.name ?? "claims-id-insightId-influencing-claims",
    path: influencing_45claims5V4wM3daSqMeta?.path ?? ":insightId()/influencing-claims",
    meta: influencing_45claims5V4wM3daSqMeta || {},
    alias: influencing_45claims5V4wM3daSqMeta?.alias || [],
    redirect: influencing_45claims5V4wM3daSqMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/claims/[id]/[insightId]/influencing-claims.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: dashboard7aDEZpjjxgMeta?.name ?? "dashboard",
    path: dashboard7aDEZpjjxgMeta?.path ?? "/dashboard",
    meta: dashboard7aDEZpjjxgMeta || {},
    alias: dashboard7aDEZpjjxgMeta?.alias || [],
    redirect: dashboard7aDEZpjjxgMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: exclusions16cBmGOhmaMeta?.name ?? undefined,
    path: exclusions16cBmGOhmaMeta?.path ?? "/exclusions",
    meta: exclusions16cBmGOhmaMeta || {},
    alias: exclusions16cBmGOhmaMeta?.alias || [],
    redirect: exclusions16cBmGOhmaMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/exclusions.vue").then(m => m.default || m),
    children: [
  {
    name: edithE4xXytR0nMeta?.name ?? "exclusions-id-edit",
    path: edithE4xXytR0nMeta?.path ?? ":id()/edit",
    meta: edithE4xXytR0nMeta || {},
    alias: edithE4xXytR0nMeta?.alias || [],
    redirect: edithE4xXytR0nMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/exclusions/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexPBwcwJSQ6OMeta?.name ?? "exclusions-id",
    path: indexPBwcwJSQ6OMeta?.path ?? ":id()",
    meta: indexPBwcwJSQ6OMeta || {},
    alias: indexPBwcwJSQ6OMeta?.alias || [],
    redirect: indexPBwcwJSQ6OMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/exclusions/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: createRyEyPG0cYZMeta?.name ?? "exclusions-create",
    path: createRyEyPG0cYZMeta?.path ?? "create",
    meta: createRyEyPG0cYZMeta || {},
    alias: createRyEyPG0cYZMeta?.alias || [],
    redirect: createRyEyPG0cYZMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/exclusions/create.vue").then(m => m.default || m)
  },
  {
    name: indexsgjVnnDfxKMeta?.name ?? "exclusions",
    path: indexsgjVnnDfxKMeta?.path ?? "",
    meta: indexsgjVnnDfxKMeta || {},
    alias: indexsgjVnnDfxKMeta?.alias || [],
    redirect: indexsgjVnnDfxKMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/exclusions/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexbW6u8wYtA5Meta?.name ?? "index",
    path: indexbW6u8wYtA5Meta?.path ?? "/",
    meta: indexbW6u8wYtA5Meta || {},
    alias: indexbW6u8wYtA5Meta?.alias || [],
    redirect: indexbW6u8wYtA5Meta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/index.vue").then(m => m.default || m)
  },
  {
    name: maintenance1sgkSZCJjXMeta?.name ?? "maintenance",
    path: maintenance1sgkSZCJjXMeta?.path ?? "/maintenance",
    meta: maintenance1sgkSZCJjXMeta || {},
    alias: maintenance1sgkSZCJjXMeta?.alias || [],
    redirect: maintenance1sgkSZCJjXMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: policies9OzHSVcefEMeta?.name ?? undefined,
    path: policies9OzHSVcefEMeta?.path ?? "/policies",
    meta: policies9OzHSVcefEMeta || {},
    alias: policies9OzHSVcefEMeta?.alias || [],
    redirect: policies9OzHSVcefEMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/policies.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93an0fGmoNVLMeta?.name ?? "policies-id",
    path: _91id_93an0fGmoNVLMeta?.path ?? ":id()",
    meta: _91id_93an0fGmoNVLMeta || {},
    alias: _91id_93an0fGmoNVLMeta?.alias || [],
    redirect: _91id_93an0fGmoNVLMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/policies/[id].vue").then(m => m.default || m),
    children: [
  {
    name: providers_45impacted38lZbCxVGbMeta?.name ?? "policies-id-providers-impacted",
    path: providers_45impacted38lZbCxVGbMeta?.path ?? "providers-impacted",
    meta: providers_45impacted38lZbCxVGbMeta || {},
    alias: providers_45impacted38lZbCxVGbMeta?.alias || [],
    redirect: providers_45impacted38lZbCxVGbMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/policies/[id]/providers-impacted.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexwvymHmXbtUMeta?.name ?? "policies",
    path: indexwvymHmXbtUMeta?.path ?? "",
    meta: indexwvymHmXbtUMeta || {},
    alias: indexwvymHmXbtUMeta?.alias || [],
    redirect: indexwvymHmXbtUMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/policies/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: reset_45password_45confirmDVYBdp7NwMMeta?.name ?? "reset-password-confirm",
    path: reset_45password_45confirmDVYBdp7NwMMeta?.path ?? "/reset-password-confirm",
    meta: reset_45password_45confirmDVYBdp7NwMMeta || {},
    alias: reset_45password_45confirmDVYBdp7NwMMeta?.alias || [],
    redirect: reset_45password_45confirmDVYBdp7NwMMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/reset-password-confirm.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordUYH1NBMxqeMeta?.name ?? "reset-password",
    path: reset_45passwordUYH1NBMxqeMeta?.path ?? "/reset-password",
    meta: reset_45passwordUYH1NBMxqeMeta || {},
    alias: reset_45passwordUYH1NBMxqeMeta?.alias || [],
    redirect: reset_45passwordUYH1NBMxqeMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: signinuAfwLtGmyOMeta?.name ?? "signin",
    path: signinuAfwLtGmyOMeta?.path ?? "/signin",
    meta: signinuAfwLtGmyOMeta || {},
    alias: signinuAfwLtGmyOMeta?.alias || [],
    redirect: signinuAfwLtGmyOMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/signin.vue").then(m => m.default || m)
  },
  {
    name: signupc5AhWqp4MDMeta?.name ?? undefined,
    path: signupc5AhWqp4MDMeta?.path ?? "/signup",
    meta: signupc5AhWqp4MDMeta || {},
    alias: signupc5AhWqp4MDMeta?.alias || [],
    redirect: signupc5AhWqp4MDMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/signup.vue").then(m => m.default || m),
    children: [
  {
    name: confirmZzjSyJIsY1Meta?.name ?? "signup-confirm",
    path: confirmZzjSyJIsY1Meta?.path ?? "confirm",
    meta: confirmZzjSyJIsY1Meta || {},
    alias: confirmZzjSyJIsY1Meta?.alias || [],
    redirect: confirmZzjSyJIsY1Meta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/signup/confirm.vue").then(m => m.default || m)
  },
  {
    name: indexaIQvO1UP6SMeta?.name ?? "signup",
    path: indexaIQvO1UP6SMeta?.path ?? "",
    meta: indexaIQvO1UP6SMeta || {},
    alias: indexaIQvO1UP6SMeta?.alias || [],
    redirect: indexaIQvO1UP6SMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/signup/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: welcomeH8NtQEcgulMeta?.name ?? "welcome",
    path: welcomeH8NtQEcgulMeta?.path ?? "/welcome",
    meta: welcomeH8NtQEcgulMeta || {},
    alias: welcomeH8NtQEcgulMeta?.alias || [],
    redirect: welcomeH8NtQEcgulMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/apps/console/pages/welcome.vue").then(m => m.default || m)
  },
  {
    name: auth_45callback852wOlvwczMeta?.name ?? "auth-callback",
    path: auth_45callback852wOlvwczMeta?.path ?? "/auth/callback",
    meta: auth_45callback852wOlvwczMeta || {},
    alias: auth_45callback852wOlvwczMeta?.alias || [],
    redirect: auth_45callback852wOlvwczMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-callback.vue").then(m => m.default || m)
  },
  {
    name: auth_45loginj4HtcCjkEiMeta?.name ?? "auth-login",
    path: auth_45loginj4HtcCjkEiMeta?.path ?? "/auth/login",
    meta: auth_45loginj4HtcCjkEiMeta || {},
    alias: auth_45loginj4HtcCjkEiMeta?.alias || [],
    redirect: auth_45loginj4HtcCjkEiMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-login.vue").then(m => m.default || m)
  },
  {
    name: auth_45statusPp8tL2HSytMeta?.name ?? "auth-status",
    path: auth_45statusPp8tL2HSytMeta?.path ?? "/auth/status",
    meta: auth_45statusPp8tL2HSytMeta || {},
    alias: auth_45statusPp8tL2HSytMeta?.alias || [],
    redirect: auth_45statusPp8tL2HSytMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-status.vue").then(m => m.default || m)
  },
  {
    name: samlgsDNFHMzJUMeta?.name ?? "AuthSaml",
    path: samlgsDNFHMzJUMeta?.path ?? "/saml",
    meta: samlgsDNFHMzJUMeta || {},
    alias: samlgsDNFHMzJUMeta?.alias || [],
    redirect: samlgsDNFHMzJUMeta?.redirect,
    component: () => import("/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth/src/pages/saml.vue").then(m => m.default || m)
  }
]