import { defu } from 'defu'
import { useAppConfiguration } from '~/stores/appConfiguration'

export default defineNuxtRouteMiddleware(async (to, from) => {
  const appConfig = useAppConfiguration()

  const config = await $fetch('/api/config')

  if (!config?.tenant.supportCognitoAuth) {
    switch (to.name) {
      case 'signin':
      case 'signup':
      case 'signup-confirm':
        return navigateTo('/auth/login', { replace: true })
      default:
        break
    }
  }

  if (process.client) {
    const appConfig = useAppConfig()

    appConfig.tenant = defu(config?.tenant ?? {}, appConfig.tenant)
    appConfig.host = config?.host
    appConfig.version = config?.version
  }

  // this is masking a significant bug with formkit
  if (
    to.name === 'exclusions-id-edit' &&
    from.name === 'exclusions-id-edit' &&
    !to.redirectedFrom
  ) {
    return navigateTo('/exclusions')
  }

  if (
    to.name === 'claims-id' &&
    to.path.includes('Claim-') &&
    appConfig.feature_claim_id_redirect
  ) {
    return navigateTo(to.path.replace('Claim-', ''))
  }
})
