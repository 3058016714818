import revive_payload_client_sMKRA44VV1 from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_encoding@0.1.13_idb-keyval@6.2.1_ioredi_g2d7tbfeghjxc43ksbzjch4y2m/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_tdAtwAinGK from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_encoding@0.1.13_idb-keyval@6.2.1_ioredi_g2d7tbfeghjxc43ksbzjch4y2m/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_cEhzO0ecBI from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_encoding@0.1.13_idb-keyval@6.2.1_ioredi_g2d7tbfeghjxc43ksbzjch4y2m/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_waNiXzKH6T from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_encoding@0.1.13_idb-keyval@6.2.1_ioredi_g2d7tbfeghjxc43ksbzjch4y2m/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_bGOwO1E5xs from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_encoding@0.1.13_idb-keyval@6.2.1_ioredi_g2d7tbfeghjxc43ksbzjch4y2m/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_XThybg6COg from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/@pinia+nuxt@0.4.11_rollup@4.14.3_typescript@5.6.2_vue@3.4.22_typescript@5.6.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/.nuxt/components.plugin.mjs";
import prefetch_client_fRYXlYendN from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_encoding@0.1.13_idb-keyval@6.2.1_ioredi_g2d7tbfeghjxc43ksbzjch4y2m/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_31FOpyktG1 from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/@vite-pwa+nuxt@0.6.0_@vite-pwa+assets-generator@0.0.11_rollup@2.79.1_vite@5.2.8_@types+node@2_6fflyqvejyac2monx7l2m43xka/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa-icons.mjs";
import pwa_client_979keg0HDa from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/@vite-pwa+nuxt@0.6.0_@vite-pwa+assets-generator@0.0.11_rollup@2.79.1_vite@5.2.8_@types+node@2_6fflyqvejyac2monx7l2m43xka/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import unocss_MzCDxu9LMj from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/.nuxt/unocss.mjs";
import plugin_ym0EGwgS5L from "/builds/rialtic/qa/argocd-releases/console-ui/packages/protected/src/runtime/plugin.ts";
import formkitPlugin_pZqjah0RUG from "/builds/rialtic/qa/argocd-releases/console-ui/apps/console/.nuxt/formkitPlugin.mjs";
import pendo_hkesixFq4o from "/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-pendo/src/runtime/pendo.ts";
import datadog_JKmUALlR6H from "/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-datadog/src/runtime/datadog.ts";
import plugin_526V8oWKGJ from "/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth0/src/runtime/plugin.ts";
import view_transitions_client_snkDrYnB0N from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_encoding@0.1.13_idb-keyval@6.2.1_ioredi_g2d7tbfeghjxc43ksbzjch4y2m/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_BLVvPRZ2eo from "/builds/rialtic/qa/argocd-releases/console-ui/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_encoding@0.1.13_idb-keyval@6.2.1_ioredi_g2d7tbfeghjxc43ksbzjch4y2m/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_8fMk05vpWN from "/builds/rialtic/qa/argocd-releases/console-ui/packages/nuxt-auth/src/runtime/plugin.ts";
import plugin_PXQeuxRKoc from "/builds/rialtic/qa/argocd-releases/console-ui/packages/ui/src/runtime/plugin.ts";
export default [
  revive_payload_client_sMKRA44VV1,
  unhead_tdAtwAinGK,
  router_cEhzO0ecBI,
  payload_client_waNiXzKH6T,
  check_outdated_build_client_bGOwO1E5xs,
  plugin_vue3_XThybg6COg,
  components_plugin_KR1HBZs4kY,
  prefetch_client_fRYXlYendN,
  pwa_icons_31FOpyktG1,
  pwa_client_979keg0HDa,
  unocss_MzCDxu9LMj,
  plugin_ym0EGwgS5L,
  formkitPlugin_pZqjah0RUG,
  pendo_hkesixFq4o,
  datadog_JKmUALlR6H,
  plugin_526V8oWKGJ,
  view_transitions_client_snkDrYnB0N,
  chunk_reload_client_BLVvPRZ2eo,
  plugin_8fMk05vpWN,
  plugin_PXQeuxRKoc
]